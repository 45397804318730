import { LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { SlhCoreIcon } from "@slhuiicon/icon-core";
import { style } from "./assets/icon.generated-style";

/**
 * Allow users to use icons
 *
 * @CustomElement slh-icon
 *
 * @cssprop --slh-color-primary - Icon primary color.
 * @cssprop --slh-color-success - Icon success color.
 * @cssprop --slh-color-info - Icon info color.
 * @cssprop --slh-color-warning - Icon warning color.
 * @cssprop --slh-color-error - Icon error color.
 */
@customElement("slh-icon")
export default class SlhIcon extends SlhCoreIcon(LitElement) {
  static styles = style;
}
