import { SlhIconFamilyInterface } from "../../icon-core.interface";
import { IconDefaultType } from "./default.type";
import {
  ICON_FAMILY_DEFAULT,
  ICON_HEIGHT,
  ICON_WIDTH,
} from "./default.constants";
import getIconContent from "./default.content";

class SlhIconFamilyDefault implements SlhIconFamilyInterface {
  // eslint-disable-next-line class-methods-use-this
  getIconContent(type: string): string | undefined {
    if (Object.values(IconDefaultType).includes(type as IconDefaultType)) {
      return getIconContent(type as IconDefaultType);
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getName(): string {
    return ICON_FAMILY_DEFAULT;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconWidth(): number {
    return ICON_WIDTH;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconHeight(): number {
    return ICON_HEIGHT;
  }
}

export default new SlhIconFamilyDefault();
