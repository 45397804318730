// eslint-disable-next-line import/prefer-default-export
export enum IconTypeApplications {
  APP_ANALYTICS_ADMIN = "app_analytics_admin",
  APP_CAMPUS_MANAGER = "app_campus_manager",
  APP_CONTENT_CREATOR = "app_content_creator",
  APP_COURSE_MANAGER = "app_course_manager",
  APP_CYPNET = "app_cypnet",
  APP_EVENT_ADMIN = "app_event_admin",
  APP_HUB = "app_hub",
  APP_LEARNING = "app_learning",
  APP_SHOP = "app_shop",
  APP_SHOP_ADMIN = "app_shop_admin",
  APP_SKILL_ADMIN = "app_skill_admin",
  APP_TESTPOOL = "app_testpool",
  APP_TIME2LEARN = "app_time2learn",
}
