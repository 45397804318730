import { SlhIconFamilyInterface } from "../../icon-core.interface";
import { IconTypeApplications } from "./applications.type";
import getIconContent from "./applications.content";
import {
  ICON_FAMILY_APPLICATIONS,
  ICON_HEIGHT,
  ICON_WIDTH,
} from "./applications.constants";

class SlhIconFamilyApplications implements SlhIconFamilyInterface {
  // eslint-disable-next-line class-methods-use-this
  getIconContent(type: string): string | undefined {
    if (
      Object.values(IconTypeApplications).includes(type as IconTypeApplications)
    ) {
      return getIconContent(type as IconTypeApplications);
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getName(): string {
    return ICON_FAMILY_APPLICATIONS;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconWidth(): number {
    return ICON_WIDTH;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconHeight(): number {
    return ICON_HEIGHT;
  }
}

export default new SlhIconFamilyApplications();
