import { SlhIconFamilyInterface } from "../../icon-core.interface";
import { IconTypeNavigation } from "./navigation.type";
import getIconContent from "./navigation.content";
import {
  ICON_FAMILY_NAVIGATION,
  ICON_HEIGHT,
  ICON_WIDTH,
} from "./navigation.constants";

class SlhIconFamilyNavigation implements SlhIconFamilyInterface {
  // eslint-disable-next-line class-methods-use-this
  getIconContent(type: string): string | undefined {
    if (
      Object.values(IconTypeNavigation).includes(type as IconTypeNavigation)
    ) {
      return getIconContent(type as IconTypeNavigation);
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getName(): string {
    return ICON_FAMILY_NAVIGATION;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconWidth(): number {
    return ICON_WIDTH;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconHeight(): number {
    return ICON_HEIGHT;
  }
}

export default new SlhIconFamilyNavigation();
