import { LitElement } from "lit";
import { property, query, state } from "lit/decorators.js";
import { Constructor } from "./constructor.type";
import {
  SlhIconFamilyInterface,
  SlhIconInterface,
} from "./icon-core.interface";
import * as IconConstants from "./icon-core.constants";
import { ICON_FAMILY_DEFAULT } from "./family/default/default.constants";
import { ICON_FAMILY_APPLICATIONS } from "./family/applications/applications.constants";
import { ICON_FAMILY_NAVIGATION } from "./family/navigation/navigation.constants";
import { ICON_FAMILY_ADMIN_NAVIGATION } from "./family/admin-navigation/admin-navigation.constants";
import SlhIconFamilyDefault from "./family/default/default.family";
import SlhIconFamilyApplications from "./family/applications/applications.family";
import SlhIconFamilyNavigation from "./family/navigation/navigation.family";
import SlhIconFamilyAdminNavigation from "./family/admin-navigation/admin-navigation.family";

const SlhCoreIcon = <T extends Constructor<LitElement>>(superClass: T) => {
  class SlhCoreIconElement extends superClass {
    /**
     * Defines the icon family which contains the icon of specified type
     * Icon family must be manually registered to the component
     */
    @property({
      type: String,
    })
    family: string = ICON_FAMILY_DEFAULT;

    /**
     * Defines type of the icon
     */
    @property({
      type: String,
    })
    type: string = "";

    /**
     * Defines color for the icon
     */
    @property({
      type: String,
    })
    color?: string;

    /**
     * Defines icon height
     */
    @property({
      type: Number,
    })
    height?: number = SlhIconFamilyDefault.getIconHeight();

    /**
     * Defines icon width
     */
    @property({
      type: Number,
    })
    width?: number = SlhIconFamilyDefault.getIconWidth();

    /**
     * Aria Label for informative icons
     */
    @property({
      type: String,
      attribute: "aria-label",
    })
    ariaLabel: string = "";

    /**
     * Adds primary styling
     */
    @property({
      type: Boolean,
      reflect: true,
    })
    primary: boolean = false;

    /**
     * Adds success styling
     */
    @property({
      type: Boolean,
      reflect: true,
    })
    success: boolean = false;

    /**
     * Adds info styling
     */
    @property({
      type: Boolean,
      reflect: true,
    })
    info: boolean = false;

    /**
     * Adds warning styling
     */
    @property({
      type: Boolean,
      reflect: true,
    })
    warning: boolean = false;

    /**
     * Adds error styling
     */
    @property({
      type: Boolean,
      reflect: true,
    })
    error: boolean = false;

    /**
     * Shows if icon is interactive
     */
    @property()
    focusable?: boolean = false;

    @state()
    protected elementState?: HTMLElement;

    @query("span > svg", false)
    private svgElement!: SVGElement;

    protected iconFamilies: Map<string, SlhIconFamilyInterface> = new Map();

    // eslint-disable-next-line class-methods-use-this
    onClick: (e: MouseEvent) => any = () => {};

    // eslint-disable-next-line class-methods-use-this
    onKeyDown: (e: KeyboardEvent) => any = () => {};

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    constructor(...args: any[]) {
      super();
      this.registerIconFamily(SlhIconFamilyDefault);
      this.registerIconFamily(SlhIconFamilyApplications);
      this.registerIconFamily(SlhIconFamilyNavigation);
      this.registerIconFamily(SlhIconFamilyAdminNavigation);
    }

    /**
     * Ensure that icon can be rendered:
     * - configured icon family must be registered
     * - configured icon type must be defined in icon family
     */
    connectedCallback() {
      super.connectedCallback();
      this.ensureIconFamily();
      this.ensureIconType();
      this.classList.add("icon-wrapper");
    }

    disconnectedCallback() {
      this.removeIconListeners();
      super.disconnectedCallback();
    }

    private removeIconListeners() {
      if (this.focusable) {
        this.elementState?.removeEventListener(
          IconConstants.EVENT_ICON_CLICK,
          this.onClick
        );
        this.removeEventListener(
          IconConstants.EVENT_ICON_KEYDOWN,
          this.onKeyDown,
          false
        );
      }
    }

    private addIconListeners() {
      if (this.focusable) {
        this.onClick = this.dispatchCustomEvent.bind(this);
        this.onKeyDown = this.keyDown.bind(this);
        this.elementState?.addEventListener(
          IconConstants.EVENT_ICON_CLICK,
          this.onClick
        );
        this.addEventListener(
          IconConstants.EVENT_ICON_KEYDOWN,
          this.onKeyDown,
          false
        );
      }
    }

    /**
     * @returns {HTMLElement} render span with icon
     */
    // eslint-disable-next-line consistent-return
    render(): HTMLElement | void {
      const iconContent = this.getIconContent();
      this.removeIconListeners();
      if (iconContent) {
        let icon = `<svg
          height="${this.height}"
          width="${this.width}"
          viewBox="0 0 ${this.width} ${this.height}"
          fill="${this.color}"
          xmlns="http://www.w3.org/2000/svg"
         `;
        if (this.ariaLabel) {
          icon += `aria-label="${this.ariaLabel}"`;
        } else if (!this.focusable) {
          icon += `aria-hidden="true"`;
        }
        if (this.focusable) {
          icon += `role="button"`;
        } else {
          icon += `role="img"`;
        }
        icon += `
          >
            ${iconContent}
          </svg>`;
        this.elementState = document.createElement("span");
        this.elementState.innerHTML = icon;
        this.addIconListeners();
        return this.elementState;
      }
    }

    firstUpdated(): void {
      if (this.focusable) {
        this.shadowRoot?.host.setAttribute("tabindex", "0");
      } else {
        this.shadowRoot?.host.removeAttribute("tabindex");
      }
    }

    updated(): void {
      if (this.family !== ICON_FAMILY_APPLICATIONS) {
        this.svgElement?.classList.toggle("primary", this.primary);
        this.svgElement?.classList.toggle("success", this.success);
        this.svgElement?.classList.toggle("info", this.info);
        this.svgElement?.classList.toggle("warning", this.warning);
        this.svgElement?.classList.toggle("error", this.error);
      }
    }

    /**
     * Register icon family to the component
     */
    registerIconFamily(family: SlhIconFamilyInterface): void {
      // eslint-disable-next-line no-console
      this.iconFamilies.set(
        family.getName() ||
          ICON_FAMILY_DEFAULT ||
          ICON_FAMILY_APPLICATIONS ||
          ICON_FAMILY_NAVIGATION ||
          ICON_FAMILY_ADMIN_NAVIGATION,
        family
      );
    }

    /**
     * @fires IconConstants.EVENT_ICON_CLICK
     */
    private dispatchCustomEvent() {
      const customEvent = new CustomEvent(IconConstants.EVENT_ICON_CLICK, {
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(customEvent);
    }

    /**
     * Handle key down
     */
    private keyDown(event: KeyboardEvent | undefined) {
      if (event instanceof KeyboardEvent && event.code === "Space") {
        event.preventDefault();
        this.dispatchCustomEvent();
      }
    }

    private ensureIconType(): void {
      if (this.type === "") {
        throw new Error(
          `${this.tagName}: Icon type must be a non-empty string`
        );
      }
    }

    private ensureIconFamily(): void {
      const family = this.getIconFamily();
      if (family === undefined) {
        throw new Error(
          `${this.tagName}: Icon family ${this.family} must be registered in the component`
        );
      }
    }

    private getIconFamily(): SlhIconFamilyInterface | undefined {
      return this.iconFamilies.has(this.family)
        ? this.iconFamilies.get(this.family)
        : undefined;
    }

    private setIconProperties(): void {
      if (this.family === ICON_FAMILY_APPLICATIONS) {
        this.width = SlhIconFamilyApplications.getIconWidth();
        this.height = SlhIconFamilyApplications.getIconHeight();
        this.color = "";
      }
    }

    /**
     * Returns content of the icon
     */
    private getIconContent(): string | undefined {
      this.ensureIconFamily();
      this.ensureIconType();
      this.setIconProperties();
      const family = this.getIconFamily();
      if (family) {
        const content = family.getIconContent(this.type);
        if (content === undefined) {
          throw new Error(
            `${this.tagName}: Icon of type ${this.type} does not exist in icon family ${this.family}`
          );
        }
        return content;
      }
      return undefined;
    }
  }

  return SlhCoreIconElement as Constructor<SlhIconInterface> & T;
};

export default SlhCoreIcon;
