import { SlhIconFamilyInterface } from "../../icon-core.interface";
import { IconTypeAdminNavigation } from "./admin-navigation.type";
import getIconContent from "./admin-navigation.content";
import {
  ICON_FAMILY_ADMIN_NAVIGATION,
  ICON_HEIGHT,
  ICON_WIDTH,
} from "./admin-navigation.constants";

class SlhIconFamilyAdminNavigation implements SlhIconFamilyInterface {
  // eslint-disable-next-line class-methods-use-this
  getIconContent(type: string): string | undefined {
    if (
      Object.values(IconTypeAdminNavigation).includes(
        type as IconTypeAdminNavigation
      )
    ) {
      return getIconContent(type as IconTypeAdminNavigation);
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getName(): string {
    return ICON_FAMILY_ADMIN_NAVIGATION;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconWidth(): number {
    return ICON_WIDTH;
  }

  // eslint-disable-next-line class-methods-use-this
  getIconHeight(): number {
    return ICON_HEIGHT;
  }
}

export default new SlhIconFamilyAdminNavigation();
