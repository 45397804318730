// eslint-disable-next-line import/prefer-default-export
export enum IconTypeNavigation {
  NAV_ACCOUNT = "nav_account",
  NAV_CAMPUS = "nav_campus",
  NAV_CONTENTCREATOR = "nav_contentcreator",
  NAV_CORPORATE_LEARNING = "nav_corporate_learning",
  NAV_CYPNET = "nav_cypnet",
  NAV_EVENTS = "nav_events",
  NAV_FINTELLIGENCE = "nav_fintelligence",
  NAV_HELP = "nav_help",
  NAV_HOME = "nav_home",
  NAV_LOGOUT = "nav_logout",
  NAV_MEDIA_LIB = "nav_media_lib",
  NAV_SETTING = "nav_setting",
  NAV_SWITCH = "nav_switch",
  NAV_TESTPOOL = "nav_testpool",
  NAV_TIME2LEARN = "nav_time2learn",
}
